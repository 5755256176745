import _defineProperty from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var __jsx = React.createElement;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import React from 'react';
import styled from 'styled-components';
import styles from './PencilBanner.style';
import RichText from '../../atoms/RichText/RichText';
var PencilBanner = function PencilBanner(_ref) {
  var bannerData = _ref.bannerData,
    className = _ref.className;
  var message = bannerData.message;
  var bannerMessage = message ? _objectSpread({}, message.json) : '';
  var anchorLink = bannerData && (bannerData === null || bannerData === void 0 ? void 0 : bannerData.anchorLink) || '';
  //Make sure text is not over max length (truncate if over 256 chars from Contentful) and displays correct format
  function truncateBannerMessage(bannerMessage) {
    var charLimit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 256;
    var displayedText = "";
    function gatherAndTruncateText(node) {
      if (Array.isArray(node)) {
        node.forEach(function (item) {
          return gatherAndTruncateText(item);
        });
      } else if (node && typeof node === 'object') {
        for (var key in node) {
          if (key === 'value' && typeof node[key] === 'string') {
            if (displayedText.length < charLimit) {
              var remainingChars = charLimit - displayedText.length;
              var truncatedText = node[key].slice(0, remainingChars);
              displayedText += truncatedText;
              node[key] = truncatedText;
            } else {
              node[key] = "";
            }
          } else {
            gatherAndTruncateText(node[key]);
          }
        }
      }
    }
    gatherAndTruncateText(bannerMessage);
    return bannerMessage;
  }
  var truncatedMessage = truncateBannerMessage(JSON.parse(JSON.stringify(bannerMessage)));
  return __jsx("div", {
    className: "nva-pencil-banner ".concat(className),
    id: anchorLink
  }, __jsx(RichText, {
    ContainerElem: "div",
    jsonContent: truncatedMessage,
    className: "nva-pencil-banner__message container"
  }));
};
PencilBanner.defaultProps = {
  className: ''
};
export default styled(PencilBanner).withConfig({
  componentId: "sc-143ytcg-0"
})(["", ";"], styles);